import React from "react";

import { Button, Container, Popup } from "../../../../../../"

import css from "./style.module.scss"


export default (props) => {
    return <Popup

        title={
            <Container effects={[1]} className={`${css.picwellContainer}`}>
                Bio
            </Container>
        } visible={props.visible} onClose={props.onClose}>

        <Container className={css.contentContainer}>
            Thanks for taking the time to read about who I am, I promise it will not be as exciting as you had hoped. I was born and raised in a small town outside of St. Louis called Millstadt Illinois. My hobbies consist of watching sports, cooking, and playing with my dog who acts like a child. I decided to work in health insurance, because I knew it would be an opportunity to help people. I have gone through my young life being helped by friends, family, and colleagues. I hope that I get a chance to help you out and make an impact on you and your life.
            </Container>
    </Popup>
}